<template>
    <SimpleTable formclass="pages/Group/form" endpoint="api/v2/group" :aditionalColumns="headers">
        <template>
            <h1>Grupos de usuários</h1>
            <v-divider class="mb-4" />
            <v-alert icon="menu_book" prominent text type="info"> Esse tipo de grupo é utilizado para relatórios de parceiros ou integração de API. </v-alert>
            <v-divider class="mb-4" />
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: "Tipo", value: "category", align: "right", sortable: true },
                { text: "Nome", value: "name", align: "right", sortable: true },
            ],
        };
    },
};
</script>